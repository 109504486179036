//REACT
import React, { useState,useEffect,Suspense } from 'react'
//FRONT
import { Box,Flex } from '@chakra-ui/react'
import "@fontsource/jost"
import './styles.css'
import { Routes, Route, useLocation } from 'react-router-dom'
//GOOGLE ANALYTICS
import ReactGA from 'react-ga4'

//SECCIONES
//FIJAS
const Header = React.lazy(() => import('./Components/header'))
const CoockiesWarning = React.lazy(() => import('./Components/cookies'))
const NotFound = React.lazy(() => import('./Components/not-found'))
//MAIN
const Main = React.lazy(() => import('./sections/Main'))
//PRODUCTOS
const Plataforma1 = React.lazy(() => import('./sections/Productos/Plataforma_1'))
const Plataforma2 = React.lazy(() => import('./sections/Productos/Plataforma_2'))
const Plataforma3 = React.lazy(() => import('./sections/Productos/Plataforma_3'))

const Matilda = React.lazy(() => import('./sections/Productos/Matilda'))
const Integraciones = React.lazy(() => import('./sections/Productos/Integraciones'))
const Canales = React.lazy(() => import('./sections/Productos/Canales'))
//EMPRESA
const Nosotros = React.lazy(() => import('./sections/Empresa/Nosotros'))
//SERVICIOS
const AtencionCliente = React.lazy(() => import('./sections/Servicios/AtencionCliente'))
const Ventas = React.lazy(() => import('./sections/Servicios/Ventas'))
//CONTACTO
const Contacto = React.lazy(() => import('./sections/Contacto'))
//POLÍTICAS
const Cookie = React.lazy(() => import('./politicas/cookies'))
const Privacidad = React.lazy(() => import('./politicas/privacidad'))
const Uso = React.lazy(() => import('./politicas/uso'))
 

const Content=()=>{


  const location = useLocation()
  useEffect(() => {
      ReactGA.initialize('G-H00C9148KH');
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location])

  //Leer los cambios en el tamaño de la pantalla, actualizar las variables y detectar si se trata de una pantalla pequeña
  const [isComputerWidth, setIsComputerWidth] = useState(window.innerWidth > 900)
  const handleResize = () => {setIsComputerWidth(window.innerWidth > 900)}
  useEffect(() => {handleResize();window.addEventListener("resize", handleResize);return () => {}},[])

  return(<> 
      <Flex justifyContent={'center'}>  
        <Header isComputerWidth={isComputerWidth}/>
      </Flex>
      <Box overflowX='hidden'>  
        <Suspense fallback={<></>}>
          <Routes>
            <Route path={'/'} element={<Main/>} />
            <Route path={'/super-service'} element={<Plataforma1/>} />
            <Route path={'/super-sale'} element={<Plataforma2/>} />
            <Route path={'/super-blitz'} element={<Plataforma3/>} />
            <Route path={'/matilda'} element={<Matilda/>} />
            <Route path={'/integraciones'} element={<Integraciones/>} />
            <Route path={'/canales'} element={<Canales/>} />
            <Route path={'/nosotros'} element={<Nosotros/>} />
            <Route path={'/atención-cliente-uso'} element={<AtencionCliente/>} />
            <Route path={'/ventas-uso'} element={<Ventas/>} />
            <Route path={'/contacto'} element={<Contacto/>} />
            <Route path={'/politica-de-cookies'} element={<Cookie/>} />
            <Route path={'/politica-de-privacidad'} element={<Privacidad/>} />
            <Route path={'/terminos-y-condiciones-de-uso'} element={<Uso/>} />
          </Routes>
        </Suspense>
      </Box>
      <CoockiesWarning/>
 
    </>)
}

export default Content

 
