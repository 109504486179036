import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './App.tsx'

// Use createRoot to create a root container
const container = document.getElementById('root')
const root = createRoot(container)

// Render your App within the root container
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
)