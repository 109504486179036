//REACT
import React from 'react'
//FRONT
import {ChakraProvider,extendTheme} from '@chakra-ui/react'
//FUNCIÓN DE LA APP
import Content from './Content/index.tsx'
import { BrowserRouter as Router } from 'react-router-dom'

const theme = extendTheme({
  breakpoints: {
    sm: '450px',
    md: '700px',
    lg: '960px',
    xl: '1200px'
  },
  colors: {
    brand: {
      clear_black: '#1A202C', 
      gray: '#4A5568',
      blue:'linear-gradient(to right, rgba(0, 123, 255), rgba(33, 180, 253))'
     },
   },
  styles: {
    global: {
      body: {
        bg: 'gray.50',
        fontFamily: 'Jost, sans-serif'
      }
    }
  }
})
 //COMPONENTE LOGIN
const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}> 
        <Router>  
          <Content/>
        </Router>  
    </ChakraProvider>
  ) 
}

export default App


 